*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #edf2f7;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #cbd5e0;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

button:focus {
  outline: none;
}

.link {
  --tw-text-opacity: 1;
  color: rgb(43 108 176 / var(--tw-text-opacity));
}

.link:hover {
  cursor: pointer;
}

.state-color-new, .state-color-new-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.state-color-actionable {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(246 224 94 / var(--tw-bg-opacity)) !important;
}

.state-color-actionable-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 240 / var(--tw-bg-opacity)) !important;
}

.state-color-qa-claimed {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(104 211 145 / var(--tw-bg-opacity)) !important;
}

.state-color-inProgress {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(99 179 237 / var(--tw-bg-opacity)) !important;
}

.state-color-inProgress-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(235 248 255 / var(--tw-bg-opacity)) !important;
}

.state-color-assigned {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(183 148 244 / var(--tw-bg-opacity)) !important;
}

.state-color-assigned-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity)) !important;
}

.state-color-error {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(252 129 129 / var(--tw-bg-opacity)) !important;
}

.state-color-error-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 245 245 / var(--tw-bg-opacity)) !important;
}

.state-color-complete {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(203 213 224 / var(--tw-bg-opacity)) !important;
}

.state-color-complete-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity)) !important;
}

.state-color-cancel {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(246 135 179 / var(--tw-bg-opacity)) !important;
}

.state-color-cancel-secondary {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 245 247 / var(--tw-bg-opacity)) !important;
}

.disabled {
  opacity: .5;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #4299e180;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

.container {
  width: 100%;
}

@media (width >= 640px) {
  .container {
    max-width: 640px;
  }
}

@media (width >= 768px) {
  .container {
    max-width: 768px;
  }
}

@media (width >= 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (width >= 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (width >= 1536px) {
  .container {
    max-width: 1536px;
  }
}

.static {
  position: static;
}

.m-1 {
  margin: .25rem;
}

.m-4 {
  margin: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.-my-1 {
  margin-top: -.25rem;
  margin-bottom: -.25rem;
}

.mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.mx-3 {
  margin-left: .75rem;
  margin-right: .75rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.-ml-3 {
  margin-left: -.75rem;
}

.-mt-1 {
  margin-top: -.25rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.-mt-8 {
  margin-top: -2rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mb-2 {
  margin-bottom: .5rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mb-3 {
  margin-bottom: .75rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-1 {
  margin-left: .25rem;
}

.ml-10 {
  margin-left: 2.5rem;
}

.ml-2 {
  margin-left: .5rem;
}

.ml-3 {
  margin-left: .75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.ml-8 {
  margin-left: 2rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-1 {
  margin-right: .25rem;
}

.mr-2 {
  margin-right: .5rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mr-auto {
  margin-right: auto;
}

.mt-1 {
  margin-top: .25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-3 {
  margin-top: .75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.h-10 {
  height: 2.5rem;
}

.h-16 {
  height: 4rem;
}

.min-h-full {
  min-height: 100%;
}

.w-1 {
  width: .25rem;
}

.w-1\/12 {
  width: 8.33333%;
}

.w-1\/2 {
  width: 50%;
}

.w-1\/3 {
  width: 33.3333%;
}

.w-1\/4 {
  width: 25%;
}

.w-1\/5 {
  width: 20%;
}

.w-1\/6 {
  width: 16.6667%;
}

.w-2\/3 {
  width: 66.6667%;
}

.w-24 {
  width: 6rem;
}

.w-4\/5 {
  width: 80%;
}

.w-40 {
  width: 10rem;
}

.w-full {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.list-inside {
  list-style-position: inside;
}

.list-disc {
  list-style-type: disc;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-divide-opacity));
}

.rounded {
  border-radius: .25rem;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-l {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem;
}

.border {
  border-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-t-2 {
  border-top-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-greyBorder {
  --tw-border-opacity: 1;
  border-color: rgb(231 232 234 / var(--tw-border-opacity));
}

.bg-backgroundWarning {
  --tw-bg-opacity: 1;
  background-color: rgb(255 248 220 / var(--tw-bg-opacity));
}

.bg-borderWarning {
  --tw-bg-opacity: 1;
  background-color: rgb(245 159 10 / var(--tw-bg-opacity));
}

.bg-checkBoxIconBackground {
  --tw-bg-opacity: 1;
  background-color: rgb(0 193 155 / var(--tw-bg-opacity));
}

.bg-chipBackground {
  --tw-bg-opacity: 1;
  background-color: rgb(207 246 238 / var(--tw-bg-opacity));
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(247 250 252 / var(--tw-bg-opacity));
}

.bg-inputBackground, .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 240 / var(--tw-bg-opacity));
}

.object-contain {
  object-fit: contain;
}

.p-10 {
  padding: 2.5rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pb-1 {
  padding-bottom: .25rem;
}

.pb-3 {
  padding-bottom: .75rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-16 {
  padding-left: 4rem;
}

.pl-2 {
  padding-left: .5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.pl-8 {
  padding-left: 2rem;
}

.pt-0 {
  padding-top: 0;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.text-left {
  text-align: left;
}

.font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
}

.font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-2xl {
  font-size: 1.5rem;
}

.text-base {
  font-size: 1rem;
}

.text-lg {
  font-size: 1.125rem;
}

.text-sm {
  font-size: .875rem;
}

.text-xs {
  font-size: .75rem;
}

.font-bold {
  font-weight: 700;
}

.font-extrabold {
  font-weight: 800;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-6 {
  line-height: 1.5rem;
}

.leading-8 {
  line-height: 2rem;
}

.leading-loose {
  line-height: 2;
}

.leading-normal {
  line-height: 1.5;
}

.leading-snug {
  line-height: 1.375;
}

.text-customBlack {
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(160 174 192 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(113 128 150 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(74 85 104 / var(--tw-text-opacity));
}

.text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(45 55 72 / var(--tw-text-opacity));
}

.text-iconWarning {
  --tw-text-opacity: 1;
  color: rgb(202 108 2 / var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(237 137 54 / var(--tw-text-opacity));
}

.text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(229 62 62 / var(--tw-text-opacity));
}

.text-yellow-400 {
  --tw-text-opacity: 1;
  color: rgb(246 224 94 / var(--tw-text-opacity));
}

.underline {
  text-decoration-line: underline;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

@media (width >= 640px) {
  .sm\:w-1\/3 {
    width: 33.3333%;
  }

  .sm\:w-1\/4 {
    width: 25%;
  }
}

header {
  background-color: #fd3001;
}

.inputfile {
  z-index: 0;
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  overflow: hidden;
}

.grade-entry {
  padding-top: 0 !important;
  padding-right: 1rem !important;
}

.inherited-input {
  width: inherit !important;
  padding-top: inherit !important;
  padding-left: inherit !important;
  padding-right: inherit !important;
}

.App {
  text-align: center;
}

.grid-row {
  flex-flow: wrap;
  display: flex;
}

.input {
  width: 100%;
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.input-no-padding {
  width: 100%;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

@media (width >= 640px) {
  .input-2-3 {
    width: 66.6667%;
  }

  .input-1-2 {
    width: 50%;
  }

  .input-1-3 {
    width: 33.3333%;
  }

  .input-1-4 {
    width: 25%;
  }

  .input-1-5 {
    width: 20%;
  }
}

.placeholder {
  --tw-text-opacity: 1;
  color: rgb(74 85 104 / var(--tw-text-opacity));
  margin-top: 6rem;
  font-family: ui-sans-serif, system-ui, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 2.25rem;
  font-weight: 700;
}

.component-heading {
  --tw-text-opacity: 1;
  color: rgb(45 55 72 / var(--tw-text-opacity));
  justify-content: flex-start;
  margin-bottom: 1.25rem;
  margin-left: 1.25rem;
  padding-top: 1.25rem;
  font-size: .875rem;
  font-weight: 700;
  display: flex;
}

.field {
  text-align: left;
  flex-direction: column;
  margin: .5rem;
  display: flex;
}

.field-label {
  --tw-text-opacity: 1;
  color: rgb(113 128 150 / var(--tw-text-opacity));
  margin-right: .25rem;
  font-size: .75rem;
}

.field-value {
  font-size: .875rem;
}

.rating-label {
  color: #0009;
  letter-spacing: .00938em;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: .759rem;
  line-height: 1.4375em;
}
/*# sourceMappingURL=index.47a53922.css.map */

.App {
  text-align: center;
}

.grid-row {
  @apply flex flex-row flex-wrap;
}

.input {
  @apply w-full pt-5 px-8;
}

.input-no-padding {
  @apply w-full pt-0 px-0;
}

@screen sm {
  .input-2-3 {
    @apply w-2/3;
  }
}

@screen sm {
  .input-1-2 {
    @apply w-1/2;
  }
}

@screen sm {
  .input-1-3 {
    @apply w-1/3;
  }
}

@screen sm {
  .input-1-4 {
    @apply w-1/4;
  }
}

@screen sm {
  .input-1-5 {
    @apply w-1/5;
  }
}

.placeholder {
  @apply text-gray-700 text-4xl font-bold font-sans mt-24;
}

.component-heading {
  @apply flex justify-start font-bold text-sm text-gray-800 ml-5 mb-5 pt-5;
}

.field {
  @apply m-2 flex flex-col text-left;
}

.field-label {
  @apply text-xs mr-1 text-gray-600;
}

.field-value {
  @apply text-sm;
}

.rating-label {
  font-size: 0.759rem;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
}

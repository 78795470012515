@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  button:focus {
    outline: none;
  }
  
  .link {
    @apply text-blue-700;
  }
  
  .link:hover {
    @apply cursor-pointer;
  }
  
  /* !important declaration to prevent Material UI from overwriting styles */
  .state-color-new {
    @apply bg-white !important;
  }
  
  .state-color-new-secondary {
    @apply bg-white !important;
  }
  
  .state-color-actionable {
    @apply bg-yellow-400 !important;
  }
  
  .state-color-actionable-secondary {
    @apply bg-yellow-100 !important;
  }
  
  .state-color-qa-claimed {
    @apply bg-green-400 !important;
  }
  
  .state-color-qa-claimed-secondary {
    @apply bg-green-100 !important;
  }
  
  .state-color-inProgress {
    @apply bg-blue-400 !important;
  }
  
  .state-color-inProgress-secondary {
    @apply bg-blue-100 !important;
  }
  
  .state-color-assigned {
    @apply bg-purple-400 !important;
  }
  
  .state-color-assigned-secondary {
    @apply bg-purple-100 !important;
  }
  
  .state-color-warning {
    @apply bg-orange-400 !important;
  }
  
  .state-color-warning-secondary {
    @apply bg-orange-100 !important;
  }
  
  .state-color-error {
    @apply bg-red-400 !important;
  }
  
  .state-color-error-secondary {
    @apply bg-red-100 !important;
  }
  
  .state-color-complete {
    @apply bg-gray-400 !important;
  }
  
  .state-color-complete-secondary {
    @apply bg-gray-300 !important;
  }
  
  .state-color-cancel {
    @apply bg-pink-400 !important;
  }
  
  .state-color-cancel-secondary {
    @apply bg-pink-100 !important;
  }
  
  .disabled {
    opacity: 0.5;
  }
}

